



































































































































































































import { COUNTRIES } from '@/shared/constants'
import { debounceInput } from '@/shared/helpers'
import {
  CertificationAuthority,
  CertificationAuthorityRole,
  IUserSubsegment,
} from '@/shared/model/certificationAuthority.model'
import { IUser, User } from '@/shared/model/user.model'
import userService from '@/shared/services/userService'
import axios from 'axios'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    ImageUpload: () => import('@/views/app/_components/ImageUpload.vue'),
  },
  data () {
    return {
      certificationAuthority: new CertificationAuthority(),
      image: null as null | string,
      imageContentType: null as any | string,
      countries: COUNTRIES,
      tab: 0,
      tabs: [this.$t('contact').toString(), this.$t('details').toString(), this.$t('trainer').toString()],
      userAutocomplete: '',
      debounce: undefined as number | undefined,
      selectedCoworker: '' as string,
      autocompleteError: '' as string,

      authorityRoles: [CertificationAuthorityRole.trainer, CertificationAuthorityRole.authorityAdmin],
      selectedAuthorityRole: CertificationAuthorityRole.trainer,
    }
  },
  computed: {
    ...mapGetters({
      users: 'userStore/getList',
      totalUsers: 'userStore/getTotal',
      certificationAuthorityError: 'certificationAuthorityStore/getError',
    }),
    availableSteps () {
      const steps = [0]

      if (
        this.certificationAuthority.name &&
        this.certificationAuthority.email &&
        this.certificationAuthority.metadata
      ) {
        steps.push(1)
      }

      if (
        this.certificationAuthority.metadata &&
        this.certificationAuthority.metadata.address &&
        this.certificationAuthority.metadata.address.country &&
        steps.includes(1)
      ) {
        steps.push(2)
      }

      if (steps.includes(2)) {
        steps.push(3)
      }

      return steps
    },
  },
  watch: {
    userAutocomplete: {
      async handler () {
        this.autocompleteError = ''
        this.debounce = debounceInput(this.debounce, this.getAllUsersForAutocomplete)
      },
    },
  },
  created () {},
  methods: {
    ...mapActions('certificationAuthorityStore', {
      createCertificationAuthority: 'createItem',
      updateCertificationAuthority: 'updateItem',
    }),
    async addUserToCertificationAuthority () {
      if (this.selectedCoworker) {
        const user = this.users.find((x: IUser) => x._id === this.selectedCoworker)
        if (user && this.certificationAuthority.users) {
          if (!this.certificationAuthority.users.find((x: IUserSubsegment) => x.user && x.user._id === user._id)) {
            this.certificationAuthority.users.push({ user: user, authorityRoles: [this.selectedAuthorityRole] })
            this.selectedCoworker = ''
          } else {
            this.autocompleteError = this.$t('dialog.this-user-is-already-selected').toString()
          }
        }
      }
    },
    async next (valid: any) {
      if (this.tab === this.tabs.length - 1) {
        await this.createCertificationAuthority({
          item: this.certificationAuthority,
        })
        this.wizardFinished()
      } else {
        this.tab++
      }
    },
    async getAllUsersForAutocomplete () {
      const query = {
        l: 10,
        lo: 0,
        sf: 'username',
        so: 'desc',
        search: { search: this.userAutocomplete },
      }
      await userService.getAllUsersPaginated(query)
    },
    setImage: function (blobContent: any) {
      if (blobContent && blobContent.image) {
        this.image = blobContent.image
        if (blobContent.imageContentType) {
          this.imageContentType = blobContent.imageContentType
        } else {
          this.imageContentType = null
        }
        this.image = blobContent.image
        if (this.certificationAuthority.metadata) {
          this.certificationAuthority.metadata.logo = blobContent.image
        }
      }
    },
    wizardFinished () {
      if (this.certificationAuthorityError) {
        if (this.certificationAuthorityError.response && this.certificationAuthorityError.response.status === 400) {
          this.$toast.error(this.$t('dialog.certification-authority-already-exists').toString())
        } else {
          this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
        }
      } else {
        this.$toast(this.$t('dialog.certification-authority-save-successfully').toString())
        this.$emit('wizardfinished')
      }
    },
  },
})
